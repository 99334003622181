import z from 'zod';

export const ALLOCATION_STATUSES = z.enum(['pending', 'executed', 'draft', 'pending_cancellation']);

export enum ALLOCATION_MODE {
  AS_PRODUCED = 'AS_PRODUCED',
  AS_CONSUMED = 'AS_CONSUMED',
}

export enum ALLOCATION_RESULT_TYPE {
  CURRENT = 'current',
  DRAFT = 'draft',
}

export enum ENERGY_UNITS {
  kwh = 'kWh',
  mwh = 'MWh',
  gwh = 'GWh',
  twh = 'TWh',
}

export type EnergyUnitKey = keyof typeof ENERGY_UNITS;

export const CREATE_ALLOCATION_MODE = z.enum(['AS_PRODUCED_SET_VALUES', 'AS_CONSUMED_MAX_FILL']);
export type CreateAllocationModeType = z.infer<typeof CREATE_ALLOCATION_MODE>;

export const intervalEnum = z.enum(['5min', '15min', '30min', 'H', 'D', 'MS', 'QS', 'Y', 'all']);
export type Interval = z.infer<typeof intervalEnum>;

export const timeAttributeEnum = z.enum(['MINUTE', 'HOUR', 'DAY_OF_WEEK', 'MONTH', 'QUARTER', 'YEAR']);
export type TimeAttribute = z.infer<typeof timeAttributeEnum>;

export const timeDefinitionEnumLower = z.enum([
  'five_minute',
  'fifteen_minute',
  'thirty_minute',
  'hourly',
  'daily',
  'monthly',
  'quarterly',
  'yearly',
  'yearly_uk_compliance_period',
  'whole_period',
]);
export type TimeDefinitionLower = z.infer<typeof timeDefinitionEnumLower>;

export const timeDefinitionEnum = z.enum([
  'FIVE_MINUTE',
  'FIFTEEN_MINUTE',
  'THIRTY_MINUTE',
  'HOURLY',
  'DAILY',
  'MONTHLY',
  'QUARTERLY',
  'YEARLY',
  'YEARLY_UK_COMPLIANCE_PERIOD',
  'WHOLE_PERIOD',
]);
export type TimeDefinition = z.infer<typeof timeDefinitionEnum>;

export const dateFormatEnum = z.enum([
  'HH:mm d MMM',
  'HH:mm',
  'MMM y',
  'MMM yy',
  'MMM',
  'P',
  'Pp',
  'QQQ yy',
  'ccc',
  'd MMM yy',
  'd MMM yyyy',
  'd MMM',
  'd MMM, HH:mm',
  'EEE d MMM',
  'EEE d MMM, HH:mm',
  'y',
  'yyyy-MM-dd',
  'yyyy-MM',
]);
export type DateFormat = z.infer<typeof dateFormatEnum>;

export const durationEnum = z.enum(['ALL', 'PT5M', 'PT10M', 'PT15M', 'PT30M', 'PT1H', 'P1D', 'P1M', 'P1Y']);
export type Durations = z.infer<typeof durationEnum>;

export const PPA_TRIGGER_TYPES = z.enum(['AUTO_PAY_AS_PRODUCED', 'AUTO_PAY_AS_CONSUMED', 'MANUAL']);
